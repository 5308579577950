import Vue from "vue";
import VueI18n from "vue-i18n";
import elementEnLocale from "element-ui/lib/locale/lang/en"; // element-ui lang
import elementFrLocale from "element-ui/lib/locale/lang/fr"; // element-ui lang
import elementEsLocale from "element-ui/lib/locale/lang/es"; // element-ui lang
import enLocale from "./en";
import frLocale from "./fr";
import esLocale from "./es";

Vue.use(VueI18n);

const messages = {
  en: {
    ...enLocale,
    ...elementEnLocale,
  },
  fr: {
    ...frLocale,
    ...elementFrLocale,
  },
  es: {
    ...esLocale,
    ...elementEsLocale,
  },
};

export function getLanguage() {
  const chooseLanguage = localStorage.getItem("aichemy--current_language");
  if (chooseLanguage) {
    return chooseLanguage;
  }

  // if has not choose language
  const language = (
    navigator.language.substring(0, 2) || navigator.browserLanguage.substring(0, 2)
  ).toLowerCase();
  const lang = Object.keys(messages).find((l) => l === language)

  return lang ?? 'en'
}
const i18n = new VueI18n({
  // set locale
  // options: en | fr | es
  locale: getLanguage(),
  // set locale messages
  messages,
});

export default i18n;
